// ==========================================================================
// Page
// ==========================================================================

.page-header {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1470px;

    &--blog {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: $base-line-height*2;
        position: relative;

        @media (min-width: 768px) {
            min-height: 432px;
        } 

        @media (min-width: 992px) {
            min-height: 560px;
        } 

        .page__heading {
            padding: $base-line-height*2 0;
            position: relative;
            z-index: 9;
        }

        .page__title {
            color: $white;
            margin: 0 0 $base-line-height;
            padding: 0 0 $base-line-height;
        }

        .page__summary {
            color: $white;
            line-height: 3rem;
            margin-bottom: 0;
        }

        &::after {
            background: #000;
            content: '';
            display: block;
            opacity: 0.5;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
        }
    }
}

.page-header__image {
    margin-bottom: 0;
    visibility: hidden;
    width: 100%;
}

.page {
    background-color: $color-white;
    padding-bottom: rem(50px);
}

.page__title,
.page h1 {
    font-weight: normal;
    margin-bottom: 45px;
    padding: 50px 0 55px;
    text-align: center;
    position: relative;
    @include rem(
        (
            font-size: 48px,
            line-height: 60px,
        )
    );
    &:after {
        background-color: map-get($colors, pampas);
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -15%;
        position: absolute;
        width: 30%;
    }
}

.page__summary {
    color: map-get($colors, dove-gray);
    font-family: $font-title;
    text-align: center;
    @include rem(
        (
            font-size: 20px,
            line-height: 40px,
            margin-bottom: $base-line-height * 2,
        )
    );
}

.page__content {
    ol,
    ul {
        margin-bottom: 0;
        padding-left: rem(20px);

        li {
            margin: rem(10px) 0 rem(15px);
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
    }

    a {
        color: $brandy;

        &[name] {
            color: inherit;
        }
    }

    .table-container {
        max-width: 100%;
        overflow-x: auto;
    }

    .asterisque {
        background: $almond;
        font-size: rem(14px);
        padding: rem(15px);
        line-height: 1.3;

        a {
            color: $mirage;
            text-decoration: underline;
        }

        p {
            margin-bottom: 0;
        }
    }

    table {
        text-align: center;
        line-height: 1.2;
        font-size: rem(14px);
        margin-bottom: 0;

        tr {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(11),
            &:nth-child(13) {
                background: $almond;
            }

            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(12) {
                background: $brandy;
            }
        }

        td,
        th {
            padding: rem(5px);
        }

        th {
            background: $tuna;
            color: $white;
        }

        p {
            margin: rem(10px) 0;
        }
    }
}

@media (min-width: 768px) {
    .page--type-page {
        margin-bottom: -76px;
        position: relative;
        top: -130px;
    }
    .page__content {
        text-align: justify;
    }
}

// ==========================================================================
// News page
// ==========================================================================

.page--type-news {
    padding-top: $base-line-height;
    .page__title {
        margin-top: 0;
        padding-top: 0;
    }
}

.page__category {
    color: $brandy;
    font-family: $font-default;
    text-align: center;
    text-transform: uppercase;
    @include rem(
        (
            font-size: 18px,
            line-height: 27px,
        )
    );
}

.page__sidebar {
    margin-top: $base-line-height * 2;
    text-align: center;
}

.page__author-picture {
    border-radius: 55px;
    display: inline-block;
}

.page__author {
    font-family: $font-default;
    margin: 0;
    @include rem(
        (
            font-size: 18px,
            line-height: 27px,
        )
    );
}

.page__created {
    border: 2px solid map-get($colors, pampas);
    border-width: 2px 0;
    margin: $base-line-height 0 0;
    padding: $base-line-height 0;
    @include rem(
        (
            font-size: 18px,
            line-height: 27px,
        )
    );
}

.page__share-title {
    color: map-get($colors, dove-gray);
    font-weight: $bold;
    text-transform: uppercase;
    margin: $base-line-height 0;
}

.page__social-link {
    border: 2px solid #cccccf;
    border-radius: 27px;
    display: inline-block;
    height: 54px;
    margin: 0 8px $base-line-height * 2;
    width: 54px;
    img {
        display: inline-block;
        margin-bottom: 0;
        padding-top: 14px;
        transition: transform 0.25s ease-in-out;
        vertical-align: top;
    }
    &:hover img {
        transform: scale(0.75);
    }
}

.page__social-link--linkedin {
    img {
        padding-top: 11px;
    }
}

@media (min-width: 768px) {
    .page--type-news {
        position: relative;
        top: -130px;
    }
}

@media (min-width: 992px) {
    .page__sidebar {
        margin-top: 0;
    }
}

// ==========================================================================
// User page
// ==========================================================================

@media (min-width: 768px) {
    .page--user {
        margin-top: 124px;
        margin-bottom: $base-line-height * 2;
    }
}

// ==========================================================================
// Twitter page
// ==========================================================================

#twitter-page {
    background-color: $brandy;
    padding: $base-line-height*2 0;
}

.twitter-page {
    color: $color-white;
    text-align: center;
}

.twitter-page__title,
.twitter-page__button {
    font-family: $font-default;
    font-weight: $bold;
    margin-bottom: 0;
    text-transform: uppercase;
    @include rem(
        (
            font-size: 18px,
            line-height: $base-line-height,
        )
    );
}

.twitter-page__button {
    border: 2px solid $color-white;
    border-radius: 16px;
    color: $color-white;
    display: inline-block;
    margin: 16px 0;
    padding: 0 16px;
    transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out;
    @include rem(
        (
            font-size: 15px,
            line-height: 28px,
        )
    );
    &:hover {
        border-color: $tuna;
        color: $tuna;
    }
}

.twitter-page__tweet-container {
    border: 1px solid $color-white;
    color: $tuna;
    left: 0px;
    margin-top: 43px;
    padding: 20px;
    position: relative;
    right: 0px;
    text-align: left;
    &:before,
    &:after {
        border-color: transparent transparent $color-white transparent;
        border-style: solid;
        border-width: 0 40px 40px 0;
        content: "";
        height: 0;
        margin-left: -20px;
        left: 50%;
        top: -40px;
        position: absolute;
        width: 0;
    }
    &:after {
        border-color: transparent transparent $brandy transparent;
        border-width: 0 38px 38px 0;
        margin-left: -19px;
        top: -38px;
    }
}

.twitter-page__tweet {
    margin-bottom: 0;
    @include rem(
        (
            font-size: 18px,
            line-height: $base-line-height,
        )
    );
    a {
        color: $color-foreground;
        text-decoration: underline;
    }
}

@media (min-width: 768px) {
    .twitter-page {
        text-align: left;
    }

    .twitter-page__title {
        float: left;
    }

    .twitter-page__button {
        float: right;
        margin: 0;
    }

    .twitter-page__tweet-container {
        &:before,
        &:after {
            left: 30%;
        }
    }
}

// ==========================================================================
// News list
// ==========================================================================

.news-list {
    margin-bottom: $base-line-height;
}

.news {
    background-color: $color-white;
    margin-bottom: $base-line-height;
    overflow: hidden;
}

.news__thumbnail {
    padding: 0;
    img {
        margin-bottom: 0;
        width: 100%;
    }
}

.news__content {
    margin-bottom: $base-line-height * 2;
    padding-top: $base-line-height;
    a {
        border-bottom: 2px solid $color-foreground;
        color: $color-foreground;
        font-weight: $bold;
        letter-spacing: -1px;
        text-transform: uppercase;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
        @include rem(
            (
                line-height: 24px,
            )
        );
        &:hover {
            border-color: $brandy;
            color: $brandy;
        }
    }
}

.news__category {
    display: block;
    color: $brandy;
    font-weight: $bold;
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
    @include rem(
        (
            font-size: 18px,
            line-height: 27px,
        )
    );
}

.news__created {
    color: map-get($colors, dove-gray);
    display: block;
    font-family: $font-title;
    margin: 0 20px;
    text-align: center;
    @include rem(
        (
            font-size: 18px,
            line-height: 27px,
        )
    );
}

.news__title {
    border-bottom: 2px solid map-get($colors, pampas);
    margin: 20px 0 $base-line-height;
    padding-bottom: 20px;
    @include rem(
        (
            font-size: 36px,
            line-height: 36px,
        )
    );
}

.news__desc {
    color: map-get($colors, dove-gray);
}

@media (min-width: 768px) {
    .news {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (min-width: 1200px) {
    .news {
        margin-left: 0;
        margin-right: 0;
    }

    .news__content {
        margin-bottom: $base-line-height;
    }

    .news__category {
        display: inline;
    }

    .news__created {
        display: inline-block;
    }
}

// ==========================================================================
// News tiny list
// ==========================================================================

.news--tiny-list {
    margin-top: $base-line-height * 2;
    margin-bottom: $base-line-height * 2;
}

.news--tiny-list__title {
    font-family: $font-title;
    font-weight: $bold;
    margin-bottom: $base-line-height;
    text-align: center;
    @include rem(
        (
            font-size: 36px,
            line-height: 36px,
        )
    );
}

.news--tiny-list__link {
    text-align: center;
    a {
        border: 2px solid $color-foreground;
        border-radius: 28px;
        color: $color-foreground;
        display: inline-block;
        font-weight: $bold;
        padding: $base-line-height/4 $base-line-height;
        text-transform: uppercase;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
        @include rem(
            (
                font-size: 18px,
                line-height: 27px,
            )
        );
        &:hover {
            border-color: $brandy;
            color: $brandy;
        }
    }
}

.news--tiny {
    overflow: hidden;
    padding: 0;
    margin-bottom: $base-line-height;
    img {
        margin-bottom: 20px;
        width: 100%;
    }
    a {
        border-bottom: 2px solid $color-foreground;
        color: $color-foreground;
        font-weight: $bold;
        letter-spacing: -1px;
        text-transform: uppercase;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
        @include rem(
            (
                font-size: 14px,
                line-height: 24px,
            )
        );
        &:hover {
            border-color: $brandy;
            color: $brandy;
        }
    }
}

.news--tiny__content {
    margin: 0 20px;
}

.news--tiny__category {
    display: block;
    color: $brandy;
    font-weight: $bold;
    letter-spacing: -1px;
    text-transform: uppercase;
    @include rem(
        (
            font-size: 14px,
            line-height: 21px,
        )
    );
}
.news--tiny__title {
    margin: 0 0 $base-line-height;
    @include rem(
        (
            font-size: 24px,
            line-height: 36px,
        )
    );
}

@media (min-width: 768px) {
    .news--tiny-list {
        margin-bottom: -66px;
        position: relative;
        top: -130px;
    }
    .news--tiny-list__link a {
        @include rem(
            (
                font-size: 24px,
                line-height: 36px,
            )
        );
    }
    .news--tiny__content {
        margin: 0 10px;
    }
}

// ==========================================================================
// Skills list
// ==========================================================================

.skills-list {
    background: $brandy;

    &__title {
        background: transparent url("../img/background--skills-illustration_2x.jpg") no-repeat right center/contain;
        color: $mirage;
        padding: 50px 0;
        position: relative;
        text-align: center;
        @include rem(
            (
                font-size: 40px,
                line-height: 40px,
            )
        );

        @media (min-width: 768px) {
            text-align: left;
            padding: 70px 0 190px 50px;
            @include rem(
                (
                    font-size: 60px,
                    line-height: 60px,
                )
            );
            &:before {
                content: "\\";
                color: $mirage;
                display: block;
                left: 0;
                position: absolute;
            }
        }

        span {
            color: #fff;
            display: block;
        }
    }

    &__image {
        margin-bottom: 0;
    }

    .skill {
        background: $ebony-clay;

        &:nth-child(even) {
            background: $mirage;

            @media only screen and (min-width: 1400px) {
                .skill__prefix {
                    background: $ebony-clay;
                    min-height: 360px;
                }
            }
        }

        &__prefix {
            background: $tuna;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding-bottom: 25px;
            padding-top: 40px;

            @media only screen and (max-width: 1399px) {
                height: auto !important;
            }
            @media only screen and (min-width: 1400px) {
                padding-bottom: 50px;
                padding-top: 80px;
            }
        }

        &__image-container {
            flex-basis: 33%;

            @media (min-width: 992px) {
                flex-basis: auto;
            }

            @media only screen and (min-width: 1400px) {
                flex: 1;
            }
        }

        &__image {
            display: inline-block;
            margin-bottom: 0;
            width: 260px;
        }

        &__title {
            color: $brandy;
            flex-basis: 66%;
            font-weight: 40;
            margin-top: 0;
            @include rem(
                (
                    font-size: 24px,
                    line-height: 28px,
                )
            );

            @media (min-width: 768px) {
                @include rem(
                    (
                        font-size: 36px,
                        line-height: 42px,
                    )
                );
            }

            @media (min-width: 992px) {
                flex-basis: auto;
            }

            @media only screen and (min-width: 1400px) {
                flex: 1;
            }
        }

        &__desc {
            color: #fff;
            padding-top: 50px;
            @include rem(
                (
                    font-size: 16px,
                    line-height: 24px,
                )
            );

            @media only screen and (min-width: 768px) {
                padding-bottom: 70px;

                li:last-child {
                    margin-bottom: 0;
                }
            }

            &--right {
                padding-bottom: 70px;
                padding-top: 0;

                @media only screen and (min-width: 768px) {
                    padding-top: 50px;
                }

                li:last-child {
                    margin-bottom: 0;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            li {
                margin-bottom: 24px;
                position: relative;
                padding-left: 35px;

                &::before {
                    color: #fff;
                    content: "\2022";
                    display: inline-block;
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            strong {
                color: $brandy;
            }
        }
    }
}
