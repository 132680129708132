// ==========================================================================
// Base
// ==========================================================================

@import "modules/all";

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus,
*:hover,
*:active {
  outline: 0 none;
}

html {
  @include rem-baseline;
  height: 100%;
}

html, html a {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

body {
  background: $white-linen;
  color: $color-foreground;
  font-family: $font-default;
  font-weight: 400;
  height: 100%;
  text-size-adjust: none;
  @include rem((
    font-size: $base-font-size,
    line-height: $base-line-height
  ));
  & > * {
    transition: filter 0.25s ease-out;
  }
}

.loading {
  display: none;
}

.front body {
  .loading {
    background-color: rgba(255, 255, 255, 0.80);
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    & ~ * {
      filter: blur(5px);
    }
  }
  .loading__inner {
    left: 50%;
    margin: -63px 0 0 -150px;
    position: absolute;
    text-align: center;
    top: 50%;
    img {
      margin-bottom: 0;
    }
  }
}

#tabs {
  margin-top: 20px;
  overflow: hidden;
  .tabs.primary {
    border: 0 none;
    float: right;
    list-style: none;
    margin: 0;
    li {
      background-color: $brandy;
      border-radius: 5px 5px 0 0;
      display: inline-block;
      margin: 0 0 0 5px;
      &.active {
        background-color: $color-white;
        a {
          color: $brandy;
        }
      }
    }
    a {
      color: $color-white;
      display: inline-block;
      padding: 0 $base-line-height/2;
    }
  }
}

.links.inline {
  left: 40px;
  list-style: none;
  padding: 0;
  position: absolute;
  top: 40px;
  z-index: 10;
  a {
    background-color: $brandy;
    border-radius: 5px;
    color: $color-white;
    display: inline-block;
    padding: 0 $base-line-height/2;
    transition: background-color 0.25s ease-in-out;
    &:hover {
      background-color: $tuna;
    }
  }
}

.item-list {
  float: left;
  margin-bottom: $base-line-height*2;
  min-height: 1px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  width: 100%;
  .pager {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  li {
    display: inline-block;
    margin: 0 10px;
    &.pager-first,
    &.pager-last {
      display: none;
    }
    &.pager-current,
    &.pager-item a {
      border: 2px solid $color-foreground;
      border-radius: 27px;
      color: $color-foreground;
      display: inline-block;
      height: 54px;
      text-align: center;
      transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
      width: 54px;
      @include rem((
        font-size: 28px,
        line-height: 47px
      ));
    }
    &.pager-current {
      opacity: 0.5;
    }
    &.pager-item a:hover {
      border-color: $brandy;
      color:  $brandy;
    }
    &.pager-previous,
    &.pager-next {
      display: block;
      margin: $base-line-height/2 0 0;
      a {
        border-bottom: 2px solid $color-foreground;
        color: $color-foreground;
        font-weight: $bold;
        letter-spacing: -1px;
        text-transform: uppercase;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
        @include rem((
          line-height: 24px
        ));
        &:hover {
          border-color: $brandy;
          color:  $brandy;
        }
      }
    }
    &.pager-previous {
      margin: 0 0 $base-line-height/2;
    }
  }
}

.notices {
  animation: bounce 1s ease 5s 3;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 15
}

.notices .notices__close {
  display: none
}

.notices a {
  display: none
}

.notices .status,.notices .error {
  background-image: none;
  color: #fff;
  @include rem((
    font-size: 14px,
    line-height: 21px
  ));
}

.notices .status {
  background-color: #47a347
}

.notices .error {
  background-color: #e73039
}

.notices .messages {
  border: 0 none;
  margin: 0;
  padding: 1.2rem 4.8rem 1.2rem 1.2rem
}

.notices .krumo-root {
  color: #000;
  border: 0 none;
  margin: 0;
  max-height: 25rem;
  overflow-y: scroll
}

@media (min-width: 992px) {
  .item-list li {
    &.pager-previous,
    &.pager-next {
      margin: 0;
      position: absolute;
      top: 0;
      @include rem((
        line-height: 54px
      ));
    }
    &.pager-previous {
      left: 40px;
    }
    &.pager-next {
      right: 40px;
    }
  }
}