// ==========================================================================
// Fonts with Bourbon
// ==========================================================================

@include font-face('Geomanist', '../fonts/geomanist/Geomanist-Regular', 400, $file-formats: woff woff2);

@include font-face('MetaPlus', '../fonts/metaplus/MetaPlus-Book', 400, $file-formats: eot woff ttf svg);
@include font-face('MetaPlus', '../fonts/metaplus/MetaPlus-Bold', 700, $file-formats: eot woff ttf svg);

@include font-face('PlayfairDisplay', '../fonts/playfairdisplay/PlayfairDisplay-Regular', 400, $file-formats: eot woff ttf svg);
@include font-face('PlayfairDisplay', '../fonts/playfairdisplay/PlayfairDisplay-Bold', 700, $file-formats: eot woff ttf svg);
@include font-face('PlayfairDisplay', '../fonts/playfairdisplay/PlayfairDisplay-BoldItalic', 700, italic, $file-formats: eot woff ttf svg);