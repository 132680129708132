// ==========================================================================
// Utils
// ==========================================================================

$spacers: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $spacer in $spacers {
  .spacer {
    &.spacer--h#{$spacer} {
      @include rem(height, #{$spacer}px);
    }
    &.spacer--w#{$spacer} {
      display: inline-block;
      @include rem(width, #{$spacer}px);
    }
  }
}

@each $name, $color in $colors {
  .background-color--#{$name} {
    background-color: $color !important;
  }
  .background-events--#{$name}:hover {
    background-color: $color !important;
  }
  .color--#{$name} {
    color: $color !important;
  }
  .color-events--#{$name}:hover {
    color: $color !important;
  }
  .border-color--#{$name} {
    border-color: $color !important;
  }
  .border-color-events--#{$name}:hover {
    border-color: $color !important;
  }
}

.background-color--inherit {
  background-color: inherit;
}
.color--inherit {
  color: inherit;
}
.border-color--inherit {
  border-color: inherit;
}

// Tablette
@media (min-width: 992px) {
  .row-table {
    display: table;
    [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: middle;
    }
  }
}
