// ==========================================================================
// Global
// ==========================================================================

.front {
  body {
    background: $mirage;
  }
}

.section-home {
  h2 {
    color: $color-white;
    padding: 50px 0;
    position: relative;
    text-align: center;
    @include rem((
      font-size: 40px,
      line-height: 40px
    ));
  }
}

@media (min-width: 768px) {
  .section-home {
    h2 {
      text-align: left;
      padding: 90px 0 90px 50px;
      @include rem((
        font-size: 60px,
        line-height: 60px
      ));
      &:before {
        content: '\\';
        display: block;
        left: 0;
        position: absolute;
      }
    }
  }
}



// ==========================================================================
// Welcome
// ==========================================================================

#welcome {
  background: transparent url('../img/background--about_2x.jpg') no-repeat center center;
  background-size: cover;
  color: $color-white;
  padding: 15% 0 13%;
  position: relative;
  text-align: center;
  .section-home--peb {
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
  }
  h1 {
    @include rem((
      font-size: 45px,
      line-height: 50px,
      margin-bottom: $base-line-height*2
    ));
    font-weight: normal;

    span {
      color: $brandy;
      display: block;
      @include rem((letter-spacing: 10px));
    }
  }
  p {
    @include rem((
      font-size: 20px,
      line-height: 36px,
      margin-bottom: $base-line-height*3
    ));
  }
  .section-home--scroll-down {
    display: block;
    margin: 0 auto;
    @include rem((
      border: 2px solid $color-white,
      border-radius: 27px,
      height: 54px,
      padding: 19px 0,
      width: 54px
    ));
    img {
      animation: bounded 2s ease infinite;
      margin: 0 auto;
      width: 10px;
    }
  }
}

@media (min-width: 768px) {
  #welcome {
    h1 {
      @include rem((
        font-size: 60px,
        line-height: 60px,
      ));
    }
  }
}

@keyframes bounded {
  0% {
    transform:translate3d(0, 0, 0)
  }
  25% {
    transform:translate3d(0, -5px, 0)
  }
  50% {
    transform:translate3d(0, 5px, 0)
  }
  100% {
    transform:translate3d(0, 0, 0)
  }
}


// ==========================================================================
// Discover
// ==========================================================================

#discover {
  .section-home--discover-bg--title {
    background: transparent url('../img/background--discover-title_2x.jpg') no-repeat center center;
    background-size: cover;
  }
    h2 {
      color: $color-white;
      font-weight: normal;
      @include rem((
        font-size: 40px,
        line-height: 40px  
      ));
      span {
        color: $mirage;
        display: block;
      }
      &:before {
        color: $tuna;
      }
    }
  .section-home--discover-photo {
    background: transparent none no-repeat center center;
    background-size: cover;
  }
  .section-home--discover-bg--desc {
    background: url('../img/background--discover-desc--25_2x.jpg') no-repeat right center;
    background-size: cover;
    color: map-get($colors, dove-gray);
    font-family: $font-title;
    padding: 60px 0 30px;
    position: relative;
    @include rem((
      font-size: 18px,
      line-height: 40px
    ));

    p {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .section-home--discover-subdesc {
    background: $white-linen;
    letter-spacing: -0.25px;
    padding: 60px 0;
    h3 {
      font-family: $font-default;
      margin-bottom: 0;
      text-transform: uppercase;
      @include rem((
        font-size: 18px,
        line-height: 36px
      ));
    }
  }
  .section-home--discover-blocks {
    background-color: #35323e;
    color: map-get($colors, pampas);
    h3 {
      color: $brandy;
      font-weight: normal;
      margin: 50px 0;
      padding-top: 80px;
      position: relative;
      text-align: center;
      @include rem((
        font-size: 36px,
        line-height: 40px
      ));
      @extend .icon-before;
      &:before {
        content: '';
        left: 50%;
        margin-left: -30px;
        position: absolute;
        top: 0;
        @extend .icon--discover-block--left;
      }
    }
    p {
      letter-spacing: -0.25px;
      text-align: center;
    }
  }
    .section-home--discover-block {
      padding-bottom: 33px;
    }
    .section-home--discover-block--left {
      background-color: #303040;
    }
    .section-home--discover-block--mid {
      background-color: #252636;
      h3 {
        &:before {
          @extend .icon--discover-block--mid;
        }
      }
    }
    .section-home--discover-block--right {
      background-color: #1a1b2d;
      h3 {
        &:before {
          @extend .icon--discover-block--right;
        }
      }
    }
}

@media (min-width: 768px) {
  #discover {
    h2 {
      float: right;
      padding: 50px 0 200px 50px;
      text-align: right;
    }
  }
}

@media (min-width: 1400px) {
  #discover {
    .section-home--discover-bg--desc {
      background: linear-gradient(0.25turn, #fff 25%, transparent 75%), url('../img/background--discover-desc_2x.jpg') no-repeat right center/cover;
    }
    .section-home--discover-blocks {
      h3 {
        padding-left: 80px;
        padding-top: 0;
        text-align: left;
        @include rem((
          line-height: 70px
        ));
        &:before {
          content: '';
          left: 0;
          margin-left: 0;
          margin-right: 0;
          top: 4px;
        }
      }
      p {
        text-align: left;
      }
    }
  }
}


// ==========================================================================
// Skills
// ==========================================================================

#skills {
  background: $tuna;
  padding: 70px 0;

  h2 {
    color: #fff;
    font-weight: normal;
    @include rem((
      font-size: 40px,
      line-height: 40px  
    ));
    padding-top: 20px;
    &:before {
      color: #fff;
    }
    span {
      color: $brandy;
      display: block;
    }
  }
  .skills-card-container {
    padding: 0;
    position: relative;
    text-align: center;
    transition: transform .25s ease-in-out;

    &:hover {
      transform: scale(1.15);
    }

    .skills-card {
      padding: 0 40px;
      &:hover .skills-card-2 {
        opacity: 0.85;
      }
    }
    .skills-card-1,
    .skills-card-2 {
      padding: 25px 0;
    }
    .skills-card-2 {
      display: none;
      background-color: $color-white;
      bottom: 0;
      height: 100%;
      left: 0;
      opacity: 0;
      padding: 30px 20px;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 0.25s ease-in-out;
      width: 100%;
      .skills-card-2-scroll {
        bottom: 134px;
        left: 20px;
        margin-bottom: 0;
        padding: 0 20px;
        overflow-y: auto;
        right: 20px;
        text-align: left;
        top: 30px;
        position: absolute;
        @include rem((
          font-size: 16px,
          line-height: 24px
        ));
      }
      ul {
        margin: 0;
        padding-left: 17px;
        li {
          margin-bottom: 12px;
        }
      }
    }
    img {
      display: inline-block;
      margin-bottom: 0;
    }
    h3 {
      color: #fff;
      display: inline-block;
      font-family: $font-default;
      font-weight: $bold;
      letter-spacing: -.025em;
      margin: 15px 0 0;
      position: relative;
      text-transform: uppercase;
      z-index: 5;
      @include rem((
        font-size: 18px,
        line-height: 30px
      ));
    }
  }
}


@media (min-width: 768px) {
  #skills {
    h2 {
      padding-bottom: 50px;
    }
    .skills-card-container {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

@media (min-width: 992px) {
  #skills {
    .skills-card-container {
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
}

@media (min-width: 1200px) {
  #skills {
    h2 span {
      display: block;
    }
    .skills-card-container {
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

@media (min-width: 1400px) {
  #skills {
    .skills-card-container {
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
}


// ==========================================================================
// Team
// ==========================================================================

.team-mate {
  background-color: $white-linen;
}
.team-mate__right {
  background: url('../img/background--team-image_2x.jpg') no-repeat center center/cover;
}
.team-mate__left {
  background: url('../img/background--team-illustration_2x.jpg') no-repeat left center/cover;
}
.team-mate__title {
  color: #fff;
  font-weight: normal;
  margin-bottom: 0;
  padding: 50px 0;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 40px,
    line-height: 40px
  ));
  span {
    color: $tuna;
  }
}
.team-mate__picture {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 600px;
}
.team-mate__name {
  color: $tuna;
  font-family: $font-default;
  font-weight: $bold;
  margin-bottom: 0;
  @include rem((
    font-size: 30px,
    line-height: 45px
  ));
}
.team-mate__status {
  font-weight: $bold;
  @include rem((
    font-size: 18px,
    line-height: 30px
  ));
}
.team-mate__desc {
  @include rem((
    font-size: 18px,
    line-height: 30px
  ));
  *:last-child {
    margin-bottom: 0;
  }
}

.team-mate__others {
  .team-mate__text {
    background-color: $bridal-health;
    padding-top: 40px;
  }
  .team-mate__desc {
    @include rem((
      font-size: 16px,
      line-height: 24px
    ));
    margin-bottom: 50px;
  }
  .team-mate__picture-container {
    background-color: #fff;
  }
}

@media (min-width: 768px) {
  .team-mate {
    .team-mate__title {
      padding: 90px 0 90px 50px;
      text-align: left;
      @include rem((
        font-size: 60px,
        line-height: 60px
      ));
      &:before {
        color: $tuna;
        content: '\\';
        display: block;
        left: 0;
        position: absolute;
      }
    }
    .team-mate__name {
      @include rem((
        font-size: 40px,
        line-height: 40px
      ));
    }
  }
}

@media (min-width: 1200px) {
  .team-mate__desc {
    margin-bottom: 80px;
  }
}

@media (min-width: 1400px) {
  .team-mate__picture:nth-child(5),
  .team-mate__picture:nth-child(7) {
    @extend .col-ws-push-3;
  }
  .team-mate__text:nth-child(6),
  .team-mate__text:nth-child(8) {
    @extend .col-ws-pull-3;
  }
}


// ==========================================================================
// Cases
// ==========================================================================

.cases {
  color: $color-white;
}

.cases__title {
  color: $brandy;
  font-weight: normal;
  margin-bottom: 0;
  padding: 50px 0;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 36px,
    line-height: 36px
  ));
  span {
    color: $color-white;
  }
}

.case__title {
  @include rem((
    font-size: 25px,
    line-height: 30px
  ));
}

.cases__controllers-container {
  background-color: #303040;
  font-weight: $bold;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 40px,
    line-height: 40px
  ));
}
  .cases__controllers-inner {
    left: 0;
    margin-top: -31px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .cases__controller {
    background-color: transparent;
    border: 2px solid $color-white;
    border-radius: 27px;
    height: 54px;
    margin: 0 15px 0 0;
    padding: 20px 19px;
    vertical-align: middle;
    width: 54px;
    img {
      margin-bottom: 0;
      transform: rotate(90deg);
      transition: transform 0.25s ease-in-out;
    }
    &:hover img {
      transform: translate3d(-5px, 0, 0) rotate(90deg);
    }
    &.cases__controller--next {
      margin: 0 0 0 15px;
      img {
        transform: rotate(-90deg);
      }
      &:hover img {
        transform: translate3d(5px, 0, 0) rotate(-90deg);
      }
    }
  }

.cases__illustration {
  background: #252636 none no-repeat center center;
  background-size: cover;
  display: none;
}

.cases__image {
  background: transparent none no-repeat center bottom;
  background-size: cover;
  height: calc(100vw / (16/9));
}

.cases__slider {
  padding-bottom: 80px;
  padding-top: 80px;
}
  .cases__item *:last-child {
    margin-bottom: 0;
    @include rem((
      font-size: 22px,
      line-height: 40px
    ));
  }

@media (min-width: 768px) {
  .cases__title {
    padding: 90px 0 90px 50px;
    text-align: left;
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
    &:before {
      color: $color-white;
      content: '\\';
      display: block;
      left: 0;
      position: absolute;
    }
  }

  .cases__controllers-container {
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
  }

  .cases__illustration {
    display: block;
  }
}

@media (min-width: 1200px) {
  .cases__controllers-container {
    @include rem((
      font-size: 40px,
      line-height: 40px
    ));
  }
  .cases__controllers-inner {
    margin-top: -27px;
  }
}

@media (min-width: 1400px) {
  .cases__controllers-container {
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
  }
  .cases__controllers-inner {
    margin-top: -31px;
  }
  .cases__controller {
    margin: 0 20px 0 0;
    &.cases__controller--next {
      margin: 0 0 0 20px;
    }
  }
}


// ==========================================================================
// Twitter
// ==========================================================================

.twitter {
  background-color: $brandy;
  padding-bottom: 80px;
}

.twitter__title {
  font-weight: normal;
  margin-bottom: 0;
  padding: 50px 0;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 40px,
    line-height: 40px
  ));
  span {
    color: $color-white;
  }
}

.twitter__tweet-container {
  border: 1px solid #fff;
  left: 0px;
  padding: 30px;
  position: relative;
  right: 0px;
  &:before,
  &:after {
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 40px 40px 0;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -20px;
    top: -40px;
    position: absolute;
    width: 0;
  }
  &:after {
    border-color: transparent transparent $brandy transparent;
    border-width: 0 38px 38px 0;
    margin-left: -19px;
    top: -38px;
  }
}

.twitter__tweet {
  margin-bottom: 0;
  @include rem((
    font-size: 22px,
    line-height: 33px
  ));
  a {
    color: $color-foreground;
    text-decoration: underline;
  }
}

@media (min-width: 768px) {
  .twitter__title {
    padding: 90px 0 90px 50px;
    text-align: left;
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
    &:before {
      color: $color-white;
      content: '\\';
      display: block;
      left: 0;
      position: absolute;
    }
  }
}

@media (min-width: 992px) {
  .twitter {
    padding-bottom: 0;
  }
  .twitter__tweet-container {
    left: 40px;
    padding: 40px;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate(0, -50%);
    &:before,
    &:after {
      border-color: transparent #ffffff transparent transparent;
      border-width: 40px 40px 0px 0;
      left: -40px;
      margin: -20px 0 0 0;
      top: 50%;
    }
    &:after {
      border-color: transparent $brandy transparent transparent;
      border-width: 38px 38px 0px 0;
      left: -38px;
      margin: -19px 0 0 0;
    }
  }
  .twitter__tweet {
    @include rem((
      font-size: 24px,
      line-height: 36px
    ));
  }
}


// ==========================================================================
// Law firm
// ==========================================================================

.law-firm {
  background-color: $color-white;
}

.law-firm__title {
  font-weight: normal;
  margin-bottom: 70px;
  padding: 50px 0 0;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 40px,
    line-height: 40px
  ));
  span {
    color: map-get($colors, christi);
    display: block;
  }
}

.law-firm__content {
  background: transparent url('../img/background--law-firm_2x.png') no-repeat right bottom;
  background-size: 83px 90px;
  padding-bottom: 60px;
  a {
    color: map-get($colors, christi);
  }
}

.law-firm__image {
  background-position: center center;
  background-size: cover;
  height: 260px;
  img {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .law-firm__content {
    background-size: 167px 181px;
  }
  .law-firm__title {
    padding: 90px 0 0 50px;
    text-align: left;
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
    &:before {
      color: $tuna;
      content: '\\';
      display: block;
      left: 0;
      position: absolute;
    }
  }
}


// ==========================================================================
// Breton anchor
// ==========================================================================

.breton-anchor {
  background-color: $color-white;
}

.breton-anchor__title {
  //background: transparent url('../img/background--breton-anchor_2x.png') no-repeat right 40px;
  //background-size: 138px 26px;
  font-weight: normal;
  margin: 0 0 70px;
  padding-top: 100px;
  position: relative;
  text-align: center;
  @include rem((
    font-size: 40px,
    line-height: 40px
  ));
  span {
    color: $brandy;
    display: block;
  }
}

.breton-anchor__content {
  padding-bottom: 60px;
  a {
    color: $brandy;
  }
}

.breton-anchor__image {
  background-position: center center;
  background-size: cover;
  height: 260px;
  img {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .breton-anchor__title {
    background-position: right 80px;
    background-size: 158px 30px;
    padding-top: 80px;
  }
  .breton-anchor__title {
    padding-left: 50px;
    text-align: left;
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
    &:before {
      color: $tuna;
      content: '\\';
      display: block;
      left: 0;
      position: absolute;
    }
  }
}

@media (min-width: 1200px) {
  .breton-anchor__title {
    background-size: 106px 20px;
  }
}

@media (min-width: 1400px) {
  .breton-anchor__title {
    background-size: 158px 30px;
  }
}

@media (min-width: 1600px) {
  .breton-anchor__title {
    background-size: 276px 52px;
  }
}


// ==========================================================================
// Contact
// ==========================================================================

#contact {
  background-color: $white-linen;
  h2 {
    color: $mirage;
    font-weight: normal;
    padding-bottom: 50px;
    padding-top: 50px;
    @include rem((
      font-size: 40px,
      line-height: 40px
    ));

    span {
      color: $brandy;
    }
  }
  .map {
    height: 300px;
  }
  .row + .row {
    background: $bridal-health;
  }
  address {
    color: map-get($colors, friar-gray);
    font-style: normal;
    margin-bottom: $base-line-height;
    padding: 50px 0;
    @include rem((
      font-size: 18px,
      line-height: 27px
    ));
    a {
      color: map-get($colors, friar-gray);
      display: block;
      @include rem((
        margin: $base-line-height 0 0
      ));
      &:hover {
        color: $brandy;
      }
    }
    .address,
    .phone,
    .email {
      padding-left: 70px;
      position: relative;
      &:before {
        background: url('../img/svg/contact--location.svg') no-repeat center center;
        background-size: 24px 24px;
        border: 2px solid map-get($colors, friar-gray);
        border-radius: 27px;
        content: '';
        display: block;
        height: 50px;
        left: 0;
        position: absolute;
        width: 50px;
      }
    }
    .phone,
    .email {
      @include rem((
        line-height: 50px
      ));
      &:before {
        background-image: url('../img/svg/contact--phone.svg');
      }
    }
    .email {
      &:before {
        background-image: url('../img/svg/contact--envelop.svg');
      }
    }
  }
}

@media (min-width: 768px) {
  #contact {
    .map {
      height: 500px;
    }
    address {
      min-height: 500px;
      margin-bottom: 0;
      padding: 100px 0 0 0;
    }
  }
}

@media (min-width: 1200px) {
  #contact {
    address {
      @include rem((
        font-size: 20px,
        line-height: 30px
      ));
    }
  }
}

// ==========================================================================
// Newsletter
// ==========================================================================

#newsletter {
  background: #303040;
  padding: 90px 0;
  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }

  h2 {
    color: #fff;
    padding-bottom: 0;
    padding-top: 0;

    span {
      color: $brandy;
    }
  }

  form {
    margin-bottom: rem(20px);
  }

  p {
    color: #fff;
    font-size: rem(12px);
    line-height: 1.3;
    margin-bottom: rem(5px);
    text-align: center;

    @media only screen and (min-width: 768px) {
      text-align: left;
    }

    a {
      color: $brandy;
    }
  }

  input[type="text"] {
    background: $tuna;
    border: 1px solid #fff;
    color: #fff;
    height: 50px;
    margin: 40px 0;
    padding: 0 25px;
    transition: border-color .25s ease-in-out, color .25s ease-in-out;
    width: 100%;
    max-width: 320px;
    @include rem((
      font-size: 20px,
      line-height: 50px
    ));

    &:hover {
      border-color: $brandy;
      color: $brandy;
    }

    @media only screen and (min-width: 768px) {
      margin: 0 0 20px;
      max-width: none;
    }

    @media only screen and (min-width: 1200px) {
      margin: 5px 0;
    }
  }

  input[type="submit"] {
    background: transparent;
    border: 2px solid $brandy;
    border-radius: 25px;
    color: $brandy;
    font-weight: 700;
    height: 50px;
    letter-spacing: -0.025em;
    margin: 0;
    padding: 0 50px;
    text-transform: uppercase;
    transition: background .25s ease-in-out, color .25s ease-in-out;
    @include rem((
      font-size: 24px,
      line-height: 50px
    ));

    &:hover {
      background: $brandy;
      color: #303040;
    }

    @media only screen and (min-width: 1200px) {
      margin: 5px 0;
    }
  }
}


// ==========================================================================
// Clients
// ==========================================================================

.clients {
  background-color: $brandy;
  padding-bottom: 0;

  &__title {
    font-weight: normal;
    margin-bottom: 0;
    padding: 50px 0 0;
    position: relative;
    text-align: center;
    @include rem((
      font-size: 40px,
      line-height: 40px
    ));
    span {
      color: $color-white;
    }
  }

  &__carousel {
    padding: 50px 0 45px;

    .owl-dots {
      line-height: 10px;
      margin-top: 30px;
      text-align: center;

      .owl-dot {
        background: #fff;
        border: 0 none;
        border-radius: 5px;
        display: inline-block;
        height: 10px;
        margin: 0 4px;
        opacity: 0.5;
        padding: 0;
        transition: opacity .25s ease-in-out;
        width: 10px;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.client {
  &__head {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    img {
      height: 60px !important;
      margin: 0 20px 0 0;
      width: 60px !important;
    }
  }

  &__name {
    font-weight: normal;
    margin: 0;
    @include rem((
      font-size: 24px,
      line-height: 24px
    ));
  }

  &__position {
    font-family: $font-default;
    font-weight: normal;
    margin: 0;
    @include rem((
      font-size: 16px,
      line-height: 24px
    ));
  }

  &__inner {
    border: 1px solid $color-white;
    color: $tuna;
    left: 0px;
    margin-top: 60px;
    padding: 20px;
    position: relative;
    right: 0px;
    text-align: left;
    &:before,
    &:after {
      border-color: transparent transparent $color-white transparent;
      border-style: solid;
      border-width: 0 40px 40px 0;
      content: '';
      height: 0;
      left: 75px;
      top: -40px;
      position: absolute;
      width: 0;
    }
    &:after {
      border-color: transparent transparent $brandy transparent;
      border-width: 0 38px 38px 0;
      margin-left: 1px;
      top: -38px;
    }
  }

  &__desc {
    @include rem((
      font-size: 24px,
      line-height: 30px
    ));

    * {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) {
  .clients__title {
    padding: 90px 0 0 50px;
    text-align: left;
    @include rem((
      font-size: 60px,
      line-height: 60px
    ));
    &:before {
      color: $color-white;
      content: '\\';
      display: block;
      left: 0;
      position: absolute;
    }
  }
}