// ==========================================================================
// Placeholders
// ==========================================================================

%hx {
  font-family: $font-title;
  @include rem(margin-top, $base-line-height*2);
  @include rem(margin-bottom, $base-line-height);

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .no-touch & { text-rendering: optimizeLegibility; } // Android bug

  a {
    color: inherit;
  }
}