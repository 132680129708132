// ==========================================================================
// Header
// ==========================================================================

header {
    background-color: $color-white;
    position: fixed;
    top: 0;
    transition: top 0.25s ease-in-out;
    width: 100%;
    z-index: 20;
    @include rem(
        (
            border-top: 4px solid $brandy,
            box-shadow: 0 1px 1px rgba(28, 28, 28, 0.1),
        )
    );
    .logged-in & {
        @include rem(
            (
                top: 64px,
            )
        );
    }
    &.header--up {
        @include rem(
            (
                top: -94px,
            )
        );
    }
    .header-logo {
        background-color: $color-white;
        display: block;
        position: relative;
        z-index: 5;
        @include rem(
            (
                padding: 0 15px,
            )
        );
        img {
            padding: 11px 0 10px;
            width: 240px;
        }
    }
    .header-toggle {
        background-color: transparent;
        border: 0 none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        @include rem(
            (
                padding: 25px 15px 26px 20px,
            )
        );
        img {
            display: block;
        }
    }
    .header-links {
        background: $brandy;
        opacity: 0;
        position: absolute;
        transition: opacity 0.25s ease-out, transform 0.25s ease-out;
        width: 100%;
        @include rem(
            (
                transform: translate3d(0, -500px, 0),
            )
        );
        &.active {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
            @include rem(
                (
                    top: 75px,
                )
            );
        }
    }
    .header-links-nav {
        align-items: center;
        display: flex;
        flex-direction: column;
        @include rem(
            (
                padding: $base-line-height/1.5 20px,
            )
        );
        a {
            color: $white;
            display: inline-block;
            text-align: center;
            @include rem(
                (
                    font-size: 20px,
                    line-height: 40px,
                    padding: 0 20px,
                    margin: 10px 0
                )
            );

            &:last-child {
                border: 1px solid $white;
                border-radius: 20px;
            }
        }
    }
    .header-links-social {
        background-color: $almond;
        float: left;
        text-align: center;
        width: 50%;
        @include rem(
            (
                padding: 0 10px,
            )
        );
        a {
            display: inline-block;
            vertical-align: middle;
            @include rem(
                (
                    line-height: 75px,
                    padding: 0 10px,
                )
            );
            img {
                transition: transform 0.25s ease-in-out;
            }
            &:hover img {
                transform: scale(0.75);
            }
        }
        &--no-lang {
            width: 100%;
        }
    }
    .header-links-lang {
        float: left;
        background-color: $white-linen;
        text-align: center;
        width: 50%;
        @include rem(
            (
                padding: 0 10px,
            )
        );
        a {
            color: $tuna;
            display: inline-block;
            text-decoration: underline;
            @include rem(
                (
                    font-size: 20px,
                    line-height: 75px,
                    padding: 0 10px,
                )
            );
            &.lang-fr--fr,
            &.lang-en--en {
                color: $brandy;
                text-decoration: none;
            }
            &:hover {
                color: $brandy;
            }
        }
        span {
            vertical-align: middle;
            @include rem(
                (
                    font-size: $base-font-size * 1.5,
                )
            );
        }
    }
    img {
        display: inline;
        margin-bottom: 0;
    }
}

@media screen and (orientation: landscape) {
    .is-mobile header {
        position: absolute;
    }
}

@media (min-width: 768px) {
    header {
        .header-logo {
            img {
                padding: 8px 0 7px;
                width: 267px;
            }
        }
    }
}

@media (min-width: 1200px) {
    header {
        overflow: hidden;
        @include rem(
            (
                padding-left: 40px,
            )
        );
        .header-logo {
            float: left;
            padding: 0;
            img {
                padding: 15px 0;
            }
        }
        .header-toggle {
            display: none;
        }
        .header-links {
            float: right;
            opacity: 1;
            overflow: hidden;
            position: static;
            transform: translate3d(0, 0, 0);
            visibility: visible;
            width: auto;
        }
        .header-links-nav {
            display: block;
            background-color: $white;
            float: left;
            @include rem(
                (
                    padding: 0 10px,
                )
            );
            a {
                color: $mirage;
                display: inline-block;
                line-height: 1;
                transition: color .25s ease-in-out, border-color .25s ease-in-out;
                
                @include rem(
                    (
                        margin: 24px 0,
                        padding: 10px 15px,
                    )
                );

                &:last-child {
                    border-color: $mirage;

                    &:focus,
                    &:hover {
                        border-color: $brandy;
                    }
                }

                &:focus,
                &:hover {
                    color: $brandy;
                }
            }
        }
        .header-links-social,
        .header-links-lang {
            width: auto;
            a {
                @include rem(
                    (
                        line-height: 90px,
                    )
                );
            }
        }
    }
}

@media (min-width: 1300px) {
    header {
        @include rem(
            (
                padding-left: 100px,
            )
        );
    }
}

// ==========================================================================
// Main
// ==========================================================================
main {
    @include rem(
        (
            margin-top: 79px,
        )
    );
}

@media (min-width: 1200px) {
    main {
        @include rem(
            (
                margin-top: 94px,
            )
        );
    }
}

// ==========================================================================
// MAIN
// ==========================================================================

.main {
    background-color: $color-white;
}

// ==========================================================================
// Footer
// ==========================================================================

footer {
    background-color: $mirage;
    color: map-get($colors, pampas);
    text-align: center;
    @include rem(
        (
            font-size: 14px,
            padding-bottom: 30px,
            padding-top: 10px,
        )
    );
    [class^="col-"],
    [class*="col-"] {
        @include rem(
            (
                padding-top: 20px,
            )
        );
    }
    .footer-social {
        text-align: center;
        @include rem(
            (
                padding: 0 10px,
            )
        );
        a {
            display: inline-block;
            vertical-align: middle;
            @include rem(
                (
                    line-height: 58px,
                )
            );
            img {
                transition: transform 0.25s ease-in-out;
            }
            &:hover img {
                transform: scale(0.75);
            }
            &:first-child {
                @include rem(
                    (
                        padding-right: 10px,
                    )
                );
            }
        }
    }
    .footer-terms {
        @include rem(
            (
                line-height: 19px,
            )
        );
        a {
            color: map-get($colors, pampas);
            text-decoration: underline;
        }
    }
    img {
        display: inline;
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    footer {
        .footer-logo {
            text-align: left;
        }
        .footer-terms {
            text-align: right;
        }
    }
}

@media (min-width: 1200px) {
    footer {
        .footer-terms {
            @include rem(
                (
                    line-height: 29px,
                )
            );
        }
    }
}
