.icon {
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.icon-before:before,
.icon-after:after {
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  margin: 0 10px 0 0;
  overflow: hidden;
  vertical-align: middle;
}
.icon-after:after {
  margin: 0 0 0 10px;
}


      .icon,
      .icon-before:before,
      .icon-after:after {
        background-image: url('../img/sprite/sprite.png');
      }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      .icon,
      .icon-before:before,
      .icon-after:after {
        background-image: url('../img/sprite/sprite@2x.png');
        background-size: 314px 404px;
      }
    }

  .icon--discover-block--left,
  .icon-before--discover-block--left:before,
  .icon-after--discover-block--left:after {
    background-position: -0px -0px;
    width: 70px;
    height: 70px;
  }
  .icon--discover-block--mid,
  .icon-before--discover-block--mid:before,
  .icon-after--discover-block--mid:after {
    background-position: -0px -70px;
    width: 70px;
    height: 70px;
  }
  .icon--discover-block--right,
  .icon-before--discover-block--right:before,
  .icon-after--discover-block--right:after {
    background-position: -0px -140px;
    width: 70px;
    height: 70px;
  }
  .icon--android-chrome-192x192,
  .icon-before--android-chrome-192x192:before,
  .icon-after--android-chrome-192x192:after {
    background-position: -0px -210px;
    width: 96px;
    height: 96px;
  }
  .icon--logo-avoxa,
  .icon-before--logo-avoxa:before,
  .icon-after--logo-avoxa:after {
    background-position: -0px -306px;
    width: 314px;
    height: 98px;
  }
