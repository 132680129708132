// ==========================================================================
// Typography
// ==========================================================================

iframe {
    max-width: 100%;
}

a {
  text-decoration: none;
  transition: color 0.25s ease-in-out;
}

strong,
b {

}

em,
i {

}

sub, sup {
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

h2 + h3 {
  margin-top: 0;
}

h1,
.h1 {
  @extend %hx;
}

h2,
.h2 {
  @extend %hx;
}

h3,
.h3 {
  @extend %hx;
}

h4,
.h4 {
  @extend %hx;
}

h5,
.h5 {
  @extend %hx;
}

h6,
.h6 {
  @extend %hx;
}

p,
ul,
ol,
dl,
blockquote,
.blockquote,
table,
.table,
img,
figure,
.media {
  @include rem(margin-bottom, $base-line-height);
}

small {
  @include rem((
    font-size: 13px,
    line-height: 18px,
    padding: 3px 0
  ));
}

// Selection text

::-moz-selection {
  background: $color-black;
  color: $color-white;
}

::selection {
  background: $color-black;
  color: $color-white;
}

hr,
.hr {
  border: 0;
  margin: 0;
  @include rem(height, 1px);
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

p {
  margin-top: 0;
}

blockquote {
  font-family: $font-title;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  @include rem((
    font-size: 24px,
    line-height: 36px
  ));
}