// ==========================================================================
// Variables
// ==========================================================================

// Colors
$black: #010101;
$white: #fff;
$brandy: #dab888;
$white-linen: #f6eee4;
$almond: #ecdbc3;
$mirage: #1a1b2d;
$tuna: #35323e;
$bridal-health: #fff9f1;
$ebony-clay: #252636;

$colors: (pampas: #efeae4, dove-gray: #626262, beryl-green: #d9e3b0, christi: #84a312, friar-gray: #817D77, bridal-heath: #fffaf3);

$color-black: $black;
$color-white: $white;

$color-foreground: $tuna;
$color-background: $tuna;

// Fonts
$font-default: 'MetaPlus', sans-serif;
$font-title: 'Geomanist', sans-serif;
$font-sans-serif: 'MetaPlus', 'Myriad Pro', 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
$font-code: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;

// Font-size + line-height
$base-font-size: 18px;
$base-line-height: 32px;

// Font-weight
$regular: 400;
$bold: 700;

// Socials
$socials: (facebook: #3b5998, twitter: #00aced, instagram: #517fa4, google: #dd4b39, pinterest: #cb2027);
