// ==========================================================================
// Outdated browser
// ==========================================================================

#outdated{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    z-index: 1500;
    background-color: #f25648;
    color: #ffffff;
}
* html #outdated{
  position: absolute;
}
#outdated h6{
  font-family: $font-title;
  font-size: 32px;
  line-height: 64px;
  margin: 30px 0 10px;
}
#outdated p{
  font-size: 18px;
  line-height: 32px;
  margin: 0;
}
#outdated #btnUpdateBrowser{
  display: block;
  position: relative;
  padding: 10px 20px;
  margin: 30px auto 0;
  width: 300px; /*need for IE*/
  color: #ffffff;
  text-decoration: none;
  border: 1px solid #ffffff;
  cursor: pointer;
}
#outdated #btnUpdateBrowser:hover{
  color: #f25648;
  background-color:#ffffff;
}
#outdated .last{
  position: absolute;
  top: 10px;
  right: 25px;
  width: 20px;
  height: 20px;
}
#outdated .last[dir='rtl']{
  right: auto !important;
  left: 25px !important;
}
#outdated #btnCloseUpdateBrowser{
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #ffffff;
  font-size: 36px;
  line-height: 36px;
}


// ==========================================================================
// Owl Carousel - Animate Plugin
// ==========================================================================

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }
  .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: none;
  }
  .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel.owl-loaded {
    display: block;
  }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }
  .owl-carousel.owl-hidden {
    opacity: 0;
  }
  .owl-carousel.owl-refresh .owl-item {
    display: none;
  }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }
  .owl-carousel.owl-rtl {
    direction: rtl;
  }
  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}


// ==========================================================================
// Custom scroll
// ==========================================================================

.custom-scroll_container {
  overflow: hidden !important;
  position: relative;
}

.custom-scroll_inner {
  overflow: scroll;
  height: 100%;
  *position: absolute; /* ie7 fix */
  *left: 0;
  *right: 0;
}
.custom-scroll_inner::-webkit-scrollbar { width: 0; height: 0; }
.custom-scroll_inner{ -ms-overflow-style: none; }


.custom-scroll_bar-y,
.custom-scroll_bar-x {
  cursor: default;
  position: absolute;
  border-radius: 4px;
  z-index: 1;
  background: $tuna;
  opacity: 1;

  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
       -o-user-select: none;
          user-select: none;

  transition: opacity .2s;
}
.custom-scroll_bar-y {
  top: 0;
  right: 0;
  width: 8px;
}
.custom-scroll_bar-x {
  left: 0;
  bottom: 1px;
  height: 8px;
}

// .custom-scroll_bar-y:hover,
// .custom-scroll_bar-x:hover {
//   opacity: .7;
// }
// .custom-scroll_bar-y:active,
// .custom-scroll_bar-x:active {
//   opacity: 1;
// }

.custom-scroll_hidden-y > .custom-scroll_bar-y,
.custom-scroll_hidden-x > .custom-scroll_bar-x {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s, visibility step-end .3s;
}

.custom-scroll-pretty_track-y {
  background-color: $tuna;
  bottom: 0;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  @include rem((
    border-radius: 4px,
    width: 8px
  ));
  .custom-scroll_hidden-y & {
    display: none;
  }
}