// ==========================================================================
// Form
// ==========================================================================

// Reset fuckin' ios8 input style
input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

// Reset fuckin' mozilla button/input spaces
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-top:-2px;
  margin-bottom: -2px;
}

label {
  display: block;
  margin-bottom: $base-line-height/2;
}
.description {
  font-style: italic;
  margin-bottom: $base-line-height;
  @include rem((
    font-size: 16px,
    line-height: 24px
  ));
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  border: 1px solid $tuna;
  padding: $base-line-height/4 $base-line-height/2;
  max-width: 100%;
  transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
  &:focus {
    border-color: $brandy;
    color: $brandy;
  }
  &.input--disabled,
  &[disabled] {

  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: $color-foreground !important;
  }
}

input[type='submit'] {
  background-color: $tuna;
  border: 0 none;
  color: $color-white;
  margin-bottom: $base-line-height;
  padding: $base-line-height/4 $base-line-height/2;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: $brandy;
  }
}

textarea {
  height: auto;
  resize: vertical;
}