// DRUPAL EXPOSED FILTERS
.ajax-progress .throbber {
    display: none;
}

.views-exposed-form {
    .views-exposed-widgets {
        margin-bottom: $base-line-height;
    }

    .views-exposed-widget {
        float: none;
        padding: 0;
    }
}

.bef-select-as-radios {
    border-bottom: 2px solid $brandy;
    display: flex;
    flex-direction: column;

    .form-item {
        margin: 0 0 $base-line-height/2 0;
    }
    
    input[type="radio"] {
        display: none;
        
        &:checked + label {
            cursor: inherit;
            color: $brandy;
        }
    }
    
    label {
        cursor: pointer;
        font-size: 1.6rem;
        padding: 0 $base-line-height/4;
        margin: 0 $base-line-height/2 0 0;
        text-transform: uppercase;
    }

    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.row--flex {
    display: flex;
    flex-flow: row wrap;

    [class*="col-"] {
        float: none;
    }
}

.row--tiny {
    margin-left: -15px;
    margin-right: -15px;
    
    [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.blog {
    align-items: stretch;
    display: flex;
    margin-bottom: $base-line-height;
    text-align: center;

    &__link {
        background: $white;
        color: $tuna;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;

        &:hover {
            .blog__author-thumbnail {
                transform: translate(50%, -75%);
            }
    
            .blog__button {
                border-color: $brandy;
                color: $brandy;
            }
        }
    }

    &__thumbnail {
        margin: 0;
        width: 100%;
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        padding: (55px + $base-font-size) $base-line-height $base-line-height;
        position: relative;
    }

    &__button {
        border-bottom: 2px solid $color-foreground;
        color: $color-foreground;
        font-weight: $bold;
        letter-spacing: -1px;
        text-transform: uppercase;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;
        @include rem(
            (
                line-height: 24px,
            )
        );
    }

    &__author-thumbnail {
        border: 9px solid $white;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 0; right: 50%;
        transform: translate(50%, -50%);
        transition: transform .25s ease-in-out;
        
        img {
            margin: 0;
        }
    }

    &__title {
        font-size: 2.4rem;
        line-height: 1.4;
        margin: auto 0 $base-font-size;
    }
    
    &__meta {
        font-size: 1.6rem;
        line-height: 2rem;
        margin: auto 0 $base-font-size*2;
    }

    &__author {
        font-weight: 700;
    }
    
    &__category {
        color: $brandy;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &--tiny {
        .blog__author-thumbnail {
            border-width: 5px;

            img {
                height: 65px;
                width: 65px;
            }
        }

        .blog__content {
            padding-top: (32.5px + $base-font-size);
        }

        .blog__title {
            font-size: 2rem;
        }
    }

    @media (max-width: 320px) {
        .blog__author-thumbnail {
            border-width: 5px;

            img {
                height: 65px;
                width: 65px;
            }
        }

        .blog__content {
            padding-top: (32.5px + $base-font-size);
        }

        .blog__title {
            font-size: 2rem;
        }
    }
}